@import "variables";
@import "mixins";

.tab {
  @include flex(row);
  width: 100%;
  text-align: center;
  background: $colorWhite;
  margin-bottom: 12px;

  input {
    display: none;
    &:checked {
     & + label {
       color: $colorNight;
       &:after {
         content: "";
         height: 2px;
         width: 100%;
         display: block;
         background: #FF0650;
         position: relative;
         top: 3px;
       }
      }
    }
  }
}

.tab__btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8799B2;
  position: relative;
  width: 100%;
  flex: 0 1 100%;
  flex-direction: column;
  gap: 0;
  white-space: nowrap;
  &--active {
    color: $colorNight;
    &:after {
      content: '';
      height: 2px;
      width: 100%;
      display: block;
      background: $colorPrimary;
      position: relative;
      top: 3px;
    }
  }
}
