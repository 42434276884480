@import "variables";
@import "mixins";

p {
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text {
  gap: 8px;
  &--primary {
    color: $colorPrimary;
  }
  &--grey1 {
    color: $colorGrey1;
  }
  &--grey2 {
    color: $colorGrey2;
  }
  &--grey3 {
    color: $colorGrey3;
  }
  &--grey4 {
    color: $colorGrey4;
  }
  &--grey5 {
    color: $colorGrey5;
  }
  &--info {
    color: $colorInfo;
    font-weight: 600;
  }
}

.title-0 {
  font-size: $textFontSizeTitle0Mobile;
  line-height: $textLineHeightTitle0Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle0Desktop;
    line-height: $textLineHeightTitle0Desktop;
  }
}
.title-1 {
  font-size: $textFontSizeTitle1Mobile;
  line-height: $textLineHeightTitle1Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle1Desktop;
    line-height: $textLineHeightTitle1Desktop;
  }
}
.title-2 {
  font-size: $textFontSizeTitle2Mobile;
  line-height: $textLineHeightTitle2Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle2Desktop;
    line-height: $textLineHeightTitle2Desktop;
  }
}
.title-3 {
  font-size: $textFontSizeTitle3Mobile;
  line-height: $textLineHeightTitle3Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle3Desktop;
    line-height: $textLineHeightTitle3Desktop;
  }
}
.title-4 {
  font-size: $textFontSizeTitle4Mobile;
  line-height: $textLineHeightTitle4Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle4Desktop;
    line-height: $textLineHeightTitle4Desktop;
  }
}
.title-5 {
  font-size: $textFontSizeTitle5Mobile;
  line-height: $textLineHeightTitle5Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeTitle5Desktop;
    line-height: $textLineHeightTitle5Desktop;
  }
}

.text-1 {
  font-size: $textFontSizeMain1Mobile;
  line-height: $textLineHeightMain1Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain1Desktop;
    line-height: $textLineHeightMain1Desktop;
  }
}
.text-2 {
  font-size: $textFontSizeMain2Mobile;
  line-height: $textLineHeightMain2Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain2Desktop;
    line-height: $textLineHeightMain2Desktop;
  }
}
.text-3 {
  font-size: $textFontSizeMain3Mobile;
  line-height: $textLineHeightMain3Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain3Desktop;
    line-height: $textLineHeightMain3Desktop;
  }
}
.text-4 {
  font-size: $textFontSizeMain4Mobile;
  line-height: $textLineHeightMain4Mobile;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain4Desktop;
    line-height: $textLineHeightMain4Desktop;
  }
}


