@import "variables";
@import "mixins";

.region {
  @include flex(row, center);
  width: 100%;
}

.region__flag {
  display: block;
  margin-right: 4px;
}

.region__code {
  font-weight: 700;
  padding-right: 8px;
  border-right: 0.5px solid $colorGrey2;
  margin-right: 12px;
  @media (min-width: $breakPointDesktopMin) {
    line-height: 20px;
  }
}

.region__icon {
  margin-left: auto;
}
