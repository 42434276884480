@import "variables";
@import "mixins";

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: $fontFamily;
  background: $colorLight;
  font-size: $textFontSizeMain1Mobile;
  line-height: $textLineHeightMain1Mobile;
  font-weight: $fontWeightPrimary;
  color: $colorNight;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain1Desktop;
    line-height: $textLineHeightMain1Desktop;
  }

}

a {
  color: $colorInfo;
  text-decoration: none;
}

input, button {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

svg {
  display: block;
}

main {
  min-height: 100%;
  padding-top: 88px;
  padding-bottom: 75px;
  @media (min-width: $breakPointDesktopMin) {
    padding-top: 84px;
    padding-bottom: 20px;
  }
}

hr,.hr {
  padding: 0;
  width: 100%;
  height: 1px;
  background: $colorGrey5;
  margin: 12px 0 16px;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

.video-mirror {
  width: 100%;
  height: 100%;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}
