@import "variables";
@import "mixins";


.p-progressbar {
  height: 4px;
  border-radius: 4px;
  background: $colorGrey5;
  .p-progressbar-value {
    background: #0078F6;
    border-radius: 4px;
  }
  .p-progressbar-label {
    color: #0078F6;
  }
}

.p-progressbar-determinate  {
  .p-progressbar-value-animate {
    transition: width .1s linear;
  }
}
