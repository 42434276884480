@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraLight.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraLightItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraLightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Light.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-LightItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Regular.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Italic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Medium.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-MediumItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-SemiBold.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-SemiBoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Bold.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-BoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraBold.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraBoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-Black.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSans3';
  src:
    url('~src/assets/fonts/SourceSans3/SourceSans3-BlackItalic.woff2') format('woff2'),
    url('~src/assets/fonts/SourceSans3/SourceSans3-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
