//@import 'swiper/swiper-bundle.min.css';
@import "variables";
@import "mixins";

swiper-container.pagination::part(container) {
  padding-bottom: 32px;
}


swiper-container::part(pagination) {
    bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

swiper-container::part(bullet) {
  display: block;
  width: 4px;
  height: 4px;
  margin: 0 1px;
  border-radius: 50%;
  background: #CDD9EA;
  opacity: 1;
}

swiper-container::part(bullet-active) {
  background: #ffffff;
  width: 8px;
  height: 8px;
  border: 1px solid $colorPrimary;
  position: relative;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    background: $colorPrimary;
  }
}

@mixin swiper-button {
  height: 100%;
  top: 0;
  margin-top: 0;
  opacity: 0.5;
  transition: .3s;
  padding: 0 16px;
  width: 22px;
  color: $colorLight;
  &:hover {
    opacity: 1;
  }
}

swiper-container::part(button-prev) {
  @include swiper-button;
  left: 0;
}

swiper-container::part(button-next) {
  @include swiper-button;
  right: 0;
}
