@import "variables";
@import "mixins";

.layout {
  overflow: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

main:not(.desktop) {
  &.no-header {
    padding-top: 0;
  }

  &.no-footer,
  &.overlay-footer {
    padding-bottom: 0;
  }
}
.container {
  width: 100%;
  padding: 0 $containerPaddingXMobile;
  @media (min-width: $breakPointDesktopMin) {
    padding: 0 $containerPaddingXDesktop;
    max-width: $containerMaxWidthDesktop;
    margin: 0 auto;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.ai-start {
  align-items: start;
}

.ai-center {
  align-items: center;
}

.cdk-global-overlay-wrapper {
  overflow: overlay;
  overflow: auto;
}
