@mixin reset-bounding() {
  padding: 0;
  margin: 0;
}

@mixin reset-list() {
  @include reset-bounding();
  list-style: none;
}

@mixin reset-link {
  text-decoration: none;
  color: inherit;
}

@mixin reset-btn {
  @include reset-bounding();
  background: transparent;
  border: none;
  &:enabled {
    cursor: pointer;
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-top-center {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-left-center {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@mixin absolute-right-center {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@mixin blurred() {
  background-color: #1C1A1AB2;
  backdrop-filter: blur(20px);
}

@mixin flex($fd: row, $ai: initial, $jc: initial, $fw: nowrap) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
  flex-wrap: $fw;

}
