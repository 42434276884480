@import "mixins";
@import "variables";

:root {
  --cropper-outline-color: #151515;
  --cropper-overlay-color: #151515;
}


.overlay-backdrop {
  background: rgba(38, 38, 38, 0.4);
}

.overlay-panel {
  //background: $colorWhite;
  //border-radius: 14px;
  //@media (min-width: $breakPointDesktopMin) {
  //  border-radius: 16px;
  //}
}

.stream-panel {
  //background: transparent;
  //border-radius: initial;
  //@media (min-width: $breakPointDesktopMin) {
  //  border-radius: initial;
  //}
}
.confirm-panel {
  //padding: $paddingLarge;
  //width: calc(100vw - (#{$paddingExSmall} * 2));
}

.cropper-panel {
  width: 100vw;
  height: 100dvh;
}
