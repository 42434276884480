@import "variables";
@import "mixins";

.toast-container {
  &.toast-bottom-center {
    .ngx-toastr {
      width: calc(100vw - 32px);
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #0B1F35;
      padding: 14px 16px 14px 52px;
      border: 0.5px solid #CDD9EA;
      border-radius: 8px;
      box-shadow: none;
      background-color: $colorWhite;
      background-size: 24px 24px;
      background-position: 16px 12px;

      &:hover {
        box-shadow: none;
      }
      @media (min-width: $breakPointDesktopMin) {
        max-width: 343px;
      }

    }
  }

}

.toast-container {
  bottom: 66px; //bottom bar height + 16px
}

.toast-success {
  background-image: url("~src/assets/img/sprite.svg#icon-check-square");
}

// Prime toast styles

.p-toast {
  width: 100%;
  //width: 300px;
  max-width: 330px;
  border-radius: 16px;
}

.p-toast-message {

}

.p-toast-icon-close {

}

.p-toast-icon {

}

.p-toast-message-content {
  border: 0.5px solid #CDD9EA;
  border-radius: 16px;
}

.p-toast-summary {

}

.p-toast-detail {

}

