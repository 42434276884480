@import "variables";

.color-primary {
  color: $colorPrimary;
}

.color-secondary {
  color: $colorGrey1;
}

.color-white {
  color: $colorWhite;
}

.color-success {
  color: $colorSuccess;
}

.color-info {
  color: $colorInfo;
}

.color-error {
  color: $colorError;
}

