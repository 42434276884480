@import "variables";
@import "mixins";

.p-slider {
  background: $colorGrey5;
  &.p-slider-horizontal {
    border-radius: 4px;
    height: 4px;

    .p-slider-handle {
      margin-top: -6px;
      margin-left: -6px;
    }
  }
  .p-slider-range {
    background: $colorPrimary;
    border-radius: 4px;
  }
  .p-slider-handle {
    height: 12px;
    width: 12px;
    background: #ffffff;
    border-radius: 100%;
    border: none;
    box-shadow: 0 0 4px 0 rgba(65, 65, 65, 0.44);
  }
}
