@import "variables";

.dropdown {
  display: flex;
  align-items: center;
}

.p-dropdown-panel {
  width: 100%;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0 0 5px rgba(187, 187, 187, 0.5);
  border-radius: 8px;
  top: 100% !important;
  padding: 4px;
}

.p-dropdown {
  position: initial !important;

  .p-overlay {
    width: 100% !important;
    min-width: 100% !important;
    top: 100% !important;
    left: 0!important;
  }
}

.p-dropdown-item {
  padding: 16px 10px 16px 16px;
  border-bottom: 1px solid $colorGrey5;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: auto;
  }
}

p-dropdownitem {
  &:first-child {
    .p-dropdown-item {
      //padding-top: 0;
    }
  }
  &:last-child {
    .p-dropdown-item {
      //padding-bottom:  0;
      border-bottom: none;
    }

  }
}

.p-dropdown-trigger {
  display: none!important;
}

.p-dropdown-items-wrapper {
  &::-webkit-scrollbar {
    width: 2px
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #CDD9EA;
  }

  &::-webkit-scrollbar-thumb {
    background: #8799B2;
  }
}


