body {
  --feed-width: 380px;
  --faps-height: calc(100vh - 104px);
  --faps-width: calc(var(--faps-height) * .56);
  --faps-left-width: 300px;
  --faps-right-width: 360px;
  --faps-main-grid-width: calc(var(--faps-width) + var(--faps-right-width) + var(--faps-left-width) + 2 * 20px);
}


$fontFamily: 'SourceSans3', sans-serif;
$fontWeightPrimary: 400;

$breakPointMobileMin: 0px;
$breakPointMobileMax: 575px;
$breakPointTabletMin: 992px;
$breakPointDesktopMin: 1092px;

$containerMaxWidthDesktop: 1092px;
$containerPaddingXMobile: 16px;
$containerPaddingXDesktop: 16px;

$colorPrimary: #FF0650;
$colorNight: #181818;
$colorWhite: #FFFFFF;
$colorLight: #F9F9F9;
$colorSuccess: #00C653;
$colorInfo: #0078F6;
$colorError: #F51B1B;
$colorGrey1: #8799B2;
$colorGrey2: #CDD9EA;
$colorGrey3: #6F7884;
$colorGrey4: #6B84A7;
$colorGrey5: #DAE2EC;

// Typography
// Titles
// Desktop
$textFontSizeTitle0Desktop: 42px;
$textLineHeightTitle0Desktop: 58px;
$textFontSizeTitle1Desktop: 28px;
$textLineHeightTitle1Desktop: 34px;
$textFontSizeTitle2Desktop: 22px;
$textLineHeightTitle2Desktop: 24px;
$textFontSizeTitle3Desktop: 18px;
$textLineHeightTitle3Desktop: 24px;
$textFontSizeTitle4Desktop: 16px;
$textLineHeightTitle4Desktop: 20px;
$textFontSizeTitle5Desktop: 14px;
$textLineHeightTitle5Desktop: 20px;
// Mobile
$textFontSizeTitle0Mobile: 24px;
$textLineHeightTitle0Mobile: 32px;
$textFontSizeTitle1Mobile: 18px;
$textLineHeightTitle1Mobile: 24px;
$textFontSizeTitle2Mobile: 16px;
$textLineHeightTitle2Mobile: 20px;
$textFontSizeTitle3Mobile: 14px;
$textLineHeightTitle3Mobile: 20px;
$textFontSizeTitle4Mobile: 13px;
$textLineHeightTitle4Mobile: 20px;
$textFontSizeTitle5Mobile: 12px;
$textLineHeightTitle5Mobile: 18px;

// Main & Sun text
// Desktop
$textFontSizeMain1Desktop: 16px;
$textLineHeightMain1Desktop: 24px;

$textFontSizeMain2Desktop: 14px;
$textLineHeightMain2Desktop: 20px;

$textFontSizeMain3Desktop: 12px;
$textLineHeightMain3Desktop: 16px;

$textFontSizeMain4Desktop: 11px;
$textLineHeightMain4Desktop: 16px;
// Mobile
$textFontSizeMain1Mobile: 14px;
$textLineHeightMain1Mobile: 20px;

$textFontSizeMain2Mobile: 13px;
$textLineHeightMain2Mobile: 20px;

$textFontSizeMain3Mobile: 12px;
$textLineHeightMain3Mobile: 16px;

$textFontSizeMain4Mobile: 10px;
$textLineHeightMain4Mobile: 16px;


$gap: 4px;

$paddingExSmall: 0.67rem; // 8px от 12
$paddingSmall: 1rem; // 12px от 12
$paddingExBase: 1.5rem; // 18px от 12
$paddingBase: 2rem; // 24px от 12
$paddingLarge: 3rem; // 36px от 12
$paddingExLarge: 4rem; // 48px от 12


