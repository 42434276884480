@import "mixins";
@import "variables";

.curtain-overlay {
  background: rgba(0,0,0,.3);
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flex(column);
  @media (min-width: $breakPointDesktopMin) {
    position: relative;
    border-radius: 16px;
    width: var(--feed-width);
    height: 100%;
  }
}

.curtain {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background: #ffffff;
  padding: 0 16px;
  margin-top: auto;
  //position: absolute;
  //bottom: 0;
  //left: 0;
  width: 100%;
  @media (min-width: $breakPointDesktopMin) {
    border-radius: 16px;
    padding: 0;
    box-shadow: 0 0 8px rgba(196, 196, 196, 0.25);
  }
}

.curtain__btn {
  background: #8799B2;
  border-radius: 100px;
  width: 30px;
  height: 3px;
  margin: 12px auto;
  display: block;
  padding: 0;
  @media (min-width: $breakPointDesktopMin) {
    display: none;
  }
}

.curtain__body {
  padding-top: 12px;
  padding-bottom: 24px;
  @media (min-width: $breakPointDesktopMin) {
    padding: 0;
  }
}

.curtain__item {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0B1F35;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid #DAE2EC;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-child {
    border-bottom: none;
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
    color: #8799B2;
  }
}

.curtain__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #262626;
  margin-bottom: 4px;
  text-align: left;
  padding-top: 16px;
}

.curtain__sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8799B2;
  text-align: left;
  margin-bottom: 4px;
}

.curtain-offset {
  .curtain-overlay {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
