@import "variables";
@import "mixins";

.form {}

.form__group {
  display: block;
  margin-bottom: 20px;
}

.form__row {
  gap: 8px;
}

.form__control {
  width: 100%;
  p-dropdown, p-inputnumber {
    width: 100%;
    & > * {
      width: 100%;
    }
  }

  &.inside-label {
    & > label {
      position: absolute;
      top: 7px;
      left: 16px;
      font-size: 14px;
      line-height: 16px;
      color: $colorGrey1;
      pointer-events: none;
    }

    .p-inputtext {
      font-size: 14px;
      padding: 24px 16px 7px;
    }
  }

  .p-dropdown {
    &.selected .p-inputtext {
      padding: 24px 16px 8px;
    }

    .p-inputtext {
      &::after {
        @include absolute-right-center();
        right: 16px;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("~src/assets/img/sprite.svg#icon-chevron-down-sm");
      }
      &[aria-expanded="true"]::after {
        background-image: url("~src/assets/img/sprite.svg#icon-chevron-up-sm");
      }
    }
    .p-dropdown-item {
      font-size: 14px;
      font-weight: 700;

      & > .selected {
        width: 100%;
        & > svg {
          margin: 0;
        }
        &::after {
          display: block;
          content: '';
          height: 20px;
          width: 20px;
          margin-left: auto;
          background-image: url("~src/assets/img/sprite.svg#icon-check-accent");
        }
      }
    }
  }
}


.form__control {
  @include flex(row);
  position: relative;
  background: $colorLight;
  border: 0.5px solid $colorGrey2;
  border-radius: 8px;

  //overflow: hidden;
  @media (min-width: $breakPointDesktopMin) {
    //padding-top: ;
  }

  &.valid {
    border-color: $colorSuccess;
    .form__icon {
      color: $colorSuccess;
    }
  }

  &.invalid {
    border-color: $colorError;
    .form__icon {
      color: $colorError;
    }
  }

  input, .p-inputtext {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-weight: 700;
    resize: none;
    padding: 15px 16px 16px;
    @media (min-width: $breakPointDesktopMin) {
      line-height: 20px;
    }
    &::placeholder {
      color: $colorGrey1;
      font-weight: 400;
    }
  }
  &--sm {
    border: 0.5px solid transparent;
    input {
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

    }
  }


  .form__control-label {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $colorGrey1;
  }

  .form__control-right-icon {
    @include absolute-right-center();
    right: 10px;
    & > * {
      vertical-align: middle;
    }
  }
}

.form__control--d-label {
  text-align: left;
  .p-inputtext {
    padding: 24px 16px 6px;
  }
}


.form__control-sub-options {
  display: flex;
  min-height: 16px;
  margin-top: 12px;

  & > button {
    @include reset-btn();
    color: $colorGrey1;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    text-decoration-line: underline;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.inactive {
      cursor: unset;
    }
  }
  &.form__control-sub-options--with-select {
    position: relative;
  }
}

.form__summary {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  & > * {
    flex: 0 0 auto;
    &:last-child {
      display: flex;
      align-items: center;
      flex-grow: 1;
      & > * {
        flex: 0 0 auto;
      }
      &:before {
        flex-grow: 1;
        content: '';
        height: 14px;
        margin: 0 4px;
        background-image: url("data:image/svg+xml;utf8,<svg width='300px' height='1px' xmlns='http://www.w3.org/2000/svg'><line x1='0' y1='0' x2='300' y2='0' style='fill: none; stroke: rgb(172,176,185); stroke-width: 2; stroke-dasharray: 2 2'/></svg>");
        background-position: bottom;
        background-repeat: no-repeat;
      }
    }
  }

  &--secondary {
    font-size: 14px;
    color: $colorGrey1;
  }
}

.form__control-avatar {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: $colorInfo;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  .avatar {
    width: 84px;
    height: 84px;
    display: block;
    border-radius: 50%;
    //background-color: $colorLight;
    margin-bottom: 8px;
    overflow: hidden;
    background-image: url("~src/assets/img/avatar-empty-light.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.form__error {
  color: $colorError;
  font-size: $textFontSizeMain3Mobile;
  line-height: $textLineHeightMain3Mobile;
  text-align: left;
  margin-top: 4px;
  @media (min-width: $breakPointDesktopMin) {
    font-size: $textFontSizeMain2Desktop;
    line-height: $textLineHeightMain2Desktop;
  }
}

.form__icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
}

.form__checkbox {
  text-align: left;
  input {
    display: none;
    &:checked {
      & + label {
        &:after {
          display: block;
        }
      }
    }
  }

  label {
    font-size: 12px;
    line-height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 36px;
    position: relative;
    @media (min-width: $breakPointDesktopMin) {
      font-size: 14px;
      //line-height: 16px;
    }


    &:before, &:after {
      content: '';
      width: 28px;
      height: 28px;
      display: block;
      background: $colorLight;
      border: 0.2px solid $colorGrey2;
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      color: $colorPrimary;
      background-image: url("~src/assets/img/sprite.svg#icon-check-accent");
      background-position: 50% 50%;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      display: none;
    }
  }
}

.form__text {
  font-size: 12px;
  line-height: 16px;
  color: $colorGrey1;
  text-align: left;
  margin-top: 12px;
}

.form__countdown {
  margin-top: 20px;
  border-top: 0.5px solid $colorGrey5;
  padding-top: 16px;
  @media (min-width: $breakPointDesktopMin) {
    font-size: 14px;
    line-height: 20px;
  }
}

//.form__group {
//  color: $colorNight;
//  background: $colorLight;
//  border: 0.5px solid $colorGrey2;
//  border-radius: 8px;
//  display: flex;
//  flex-direction: row;
//  padding: 15px 16px 16px;
//  position: relative;
//  margin-bottom: 12px;
//
//  &.valid {
//    border-color: $colorSuccess;
//
//    .form__icon {
//      color: $colorSuccess;
//    }
//  }
//}
//
//.form__icon {
//  position: absolute;
//  right: 16px;
//  top: 50%;
//  transform: translateY(-50%);
//  width: 20px;
//  height: 20px;
//  flex: 0 0 20px;
//}
//
//.form__label {
//
//}
//
//.form__control {
//  width: 100%;
//  border: none;
//  padding: 0;
//  outline: none;
//  background: transparent;
//  color: #0B1F35;
//  font-weight: 700;
//
//  &::placeholder {
//    color: $colorGrey1;
//    font-weight: 400;
//  }
//}
//
//.form__checkbox {
//  text-align: left;
//  input {
//    display: none;
//
//    &:checked {
//      & + label {
//        &:after {
//          display: block;
//        }
//      }
//    }
//  }
//
//  label {
//    font-size: 12px;
//    line-height: 28px;
//    display: flex;
//    flex-direction: row;
//    align-items: center;
//    padding-left: 36px;
//    position: relative;
//    @media (min-width: $breakPointDesktopMin) {
//      font-size: 14px;
//      line-height: 16px;
//    }
//
//
//    &:before, &:after {
//      content: '';
//      width: 28px;
//      height: 28px;
//      display: block;
//      background-color: $colorLight;
//      border: 0.2px solid $colorGrey2;
//      border-radius: 4px;
//      position: absolute;
//      left: 0;
//      top: 50%;
//      transform: translateY(-50%);
//    }
//
//    &:after {
//      color: $colorPrimary;
//      background-image: url("~src/assets/img/sprite.svg#icon-check-accent");
//      background-position: 50% 50%;
//      background-size: 20px 20px;
//      background-repeat: no-repeat;
//      display: none;
//    }
//  }
//}
//
//.form__text {
//  font-size: 12px;
//  line-height: 16px;
//  color: $colorGrey1;
//  text-align: left;
//}

//
//.form__error {
//  font-weight: 400;
//  font-size: 12px;
//  line-height: 16px;
//  color: $colorError;
//  text-align: left;
//  margin-bottom: 12px;
//  margin-top: -8px;
//}
