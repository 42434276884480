@import "video.js/src/css/video-js";
@import "variables";
@import "mixins";

.video-js {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;

  &:hover,
  &:focus, {
    .vjs-big-play-button {
      background: transparent;
    }
  }

  .vjs-tech {
    background: rgba(38, 38, 38, 0.4) !important;
  }

  .vjs-big-play-button,
  .vjs-big-pause-button {
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    //width: 52px;
    //height: 52px;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    .vjs-icon-placeholder {
      &:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .vjs-big-volume-button {
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 24px;
    height: 24px;
    z-index: 1;
    @include reset-btn;
    .vjs-icon-placeholder {
      &:before,
      &:after {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        background-size: contain;
      }

      &:before {
        background-image: url("~src/assets/img/sprite.svg#icon-volume");
      }
      &:after {
        background-image: url("~src/assets/img/sprite.svg#icon-mute");
      }
    }

    &.vjs-big-button-volume {
      .vjs-icon-placeholder {
        &:before {
          display: block;
        }
        &:after {
          display: none;
        }
      }
    }
    &.vjs-big-button-muted {
      .vjs-icon-placeholder {
        &:before {
          display: none;
        }
        &:after {
          display: block;
        }
      }
    }
  }
}

.vjs-poster {
  background-size: cover;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    background-color: rgba(38, 38, 38, 0.4);
  }
}

.video-js {
  &.border-rounding__top.vjs-tech,
  &.border-rounding__top + .vjs-poster,
  &.border-rounding__top + .vjs-poster:before {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  &.border-rounding__all.vjs-tech,
  &.border-rounding__all + .vjs-poster,
  &.border-rounding__all + .vjs-poster:before {
    border-radius: 14px;
  }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  width: 52px;
  height: 52px;
  display: block;
  background-image: url("~src/assets/img/sprite.svg#icon-play");
  background-size: contain;
}

.video-js .vjs-big-pause-button .vjs-icon-placeholder:before {
  content: '';
  width: 52px;
  height: 52px;
  display: block;
  background-image: url("~src/assets/img/sprite.svg#icon-pause");
  background-size: contain;
}

.vjs-user-active {
  &.vjs-playing {
    .vjs-big-pause-button {
      display: block!important;
    }
    .vjs-big-play-button {
      display: none!important;
    }
  }
  &.vjs-paused {
    .vjs-big-pause-button {
      display: none!important;
    }
    .vjs-big-play-button {
      display: block!important;
    }
  }
}
.vjs-user-inactive {
  .vjs-big-play-button {
    display: none!important;
  }
  .vjs-big-pause-button {
    display: none!important;
  }

}

.vjs-paused {
  .vjs-big-play-button {
    display: block!important;
    .vjs-icon-placeholder:before {
      background-image: url("~src/assets/img/sprite.svg#icon-play");
    }
  }
}

.vjs-text-track-display {
  pointer-events: auto;
}

