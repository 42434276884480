@import "variables";
@import "mixins";

.main-grid {
  display: block;
  //max-width: var(--feed-width);
  margin: 0 auto;
  @media (min-width: $breakPointTabletMin) {
    max-width: 1092px;
  }

  &.desktop {
    display: grid;
    grid-template-columns: 1fr var(--feed-width) 1fr;
    gap: 20px;
  }
}

.main-grid.faps {
  //max-width: 100vw;
  //padding: 0;

  &.desktop {
    grid-template-columns: var(--faps-left-width) 1fr var(--faps-right-width);
    //max-width: var(--faps-main-grid-width);
  }
}

.main-grid__sticky {
  position: sticky;
  top: 84px;
  display: block;
  width: 100%;
  height: var(--faps-height);
  max-height: 100%;
}

::ng-deep  .p-toast-message {
  margin-top: 8px;
}

.message__top {
  margin-bottom: 12px;
}
