@import "variables";
@import "mixins";

.panel-widget {
  @include flex(row);
  gap: 12px;
  input {
    & + .panel-widget__item {
      svg {
        color: $colorGrey1;
      }
    }
    &:checked {
      & + .panel-widget__item {
        &::after {
          background-image: url("~src/assets/img/sprite.svg#icon-radio-on");
          background-position: 0.01px 0.01px; //fallback for safari browser
        }

        svg {
          color: $colorPrimary;
        }
      }
    }
  }
  &--checkbox {
    margin-top: 16px;
    padding-bottom: 28px;
  }
}

.panel-widget__item {
  flex: 0 1 auto;
  min-width: 97px;
  background: $colorLight;
  border-radius: 4px;
  padding: 8px 8px 4px;
  position: relative;
  cursor: pointer;

  &--fluid {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: -28px;
    background-image: url("~src/assets/img/sprite.svg#icon-radio-off");
    background-position: 0 0;
    background-size: 20px 20px;
    background-repeat: no-repeat;
  }
}

//.panel-widget input:not(:checked) + .panel-widget__item::after {
//  background-image: url("~src/assets/img/sprite.svg#icon-radio-off");
//  background-position: 0 0;
//  //background: red;
//}
//.panel-widget input:checked + .panel-widget__item::after {
//  background-image: url("~src/assets/img/sprite.svg#icon-radio-on");
//  background-position: 0.01px 0.01px;
//  //background: blue;
//}

.panel-widget__img {
  width: 103px;
  height: 97px;
  object-fit: contain;
  margin: -8px -8px -4px;
  display: block;
}

.panel-widget__title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}
.panel-widget__text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $colorGrey1;
}
.panel-widget__icon {
  display: block;
  width: 28px;
  height: 28px;
  margin-bottom: 7px;
}

.panel-widget__badge {
  background: #0078F6;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px 4px;
}

.panel-widget__tariff {
  flex: 0 1 auto;
  min-width: 103px;
  background: $colorLight;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  position: relative;
  &:last-child {
    width: 100%;
  }


  svg {
    color: $colorGrey1;
  }
}

.tariff__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.tariff__duration {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $colorGrey1;
}
