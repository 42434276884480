@import "variables";
@import "mixins";

.btn {
  @include reset-btn;
  padding: 7px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  //width: 100%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  &--primary {
    color: $colorWhite;
    background: $colorPrimary;
    &:hover {
      background: #E50044;
    }
    &:active {
      background: #D1003E;
    }
    &:disabled {
      background: #FF9EBB;
      cursor: not-allowed;
    }

    &.btn--link {
      color: $colorPrimary;
      &:hover {
        color: #E50044;
      }
      &:active {
        color: #D1003E;
      }
    }

    &.btn.btn--outline {
      background: transparent;
      color: $colorPrimary;
      border: 1px solid $colorPrimary;
    }
  }

  &.btn--outline {
    background: transparent;
    color: $colorWhite;
    border: 1px solid $colorWhite;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  &--inline {
    display: inline-flex;
  }

  &--info {
    &.btn--link {
      color: $colorInfo;
    }
  }

  &--secondary {
    color: #ffffff;
    background: $colorGrey1;

    &:hover{
      background: #677E9D;
    }

    &.btn--link {
      color: $colorGrey1;
    }
  }

  &--grey5 {
    background: $colorGrey5;
    color: $colorWhite;
  }

  &--sm {
    padding: 3px 16px;
  }

  &--lg {
    height: 52px;
    min-width: 140px;
  }

  &--link {
    border: none;
    background: transparent;
    padding: 0;
    &:hover,
    &:active,
    &:disabled {
      background: transparent;
    }
  }

  &--round {
    border-radius: 50px;
  }

  &--icon {
    padding: 0;
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }

    &.btn--lg {
      width: 34px;
      height: 34px;
      min-width: auto;
    }
  }

  &--close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    width: 24px;
    height: 24px;
    color: $colorGrey1;
    padding: 0;

    svg {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  &--full {
    width: 100%;
    justify-content: center;
  }
}

.btn__step-back{
  width: 24px;
  margin-right: 12px;
  svg{
    width: 24px;
    height: 24px;
  }
  &:hover{
    border-radius: 8px;
    background: #FF06500D;
  }
}

.btn__icon {
  color: inherit;
  &--left {
    margin-right: 8px;
  }

  &--right {
    margin-left: 8px;
  }


}

.btn-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

