@import "variables";
@import "mixins";

.panel {
  position: relative;
  display: block;
  padding: 20px;
  border-radius: 16px;
  background: $colorWhite;

  &::-webkit-scrollbar {
    width: 2px
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $colorGrey2;
  }

  &::-webkit-scrollbar-thumb {
    background: $colorGrey1;
  }

  &--secondary {
    background: $colorLight;
    border-radius: 8px;
    border: 0.5px solid $colorGrey2;
    padding: 16px;
    @media (min-width: $breakPointDesktopMin) {
      padding: 20px;
    }
  }

  &--notice {
    background: $colorLight;
    border-radius: 8px;
    color: $colorGrey3;
    padding: 8px;
    .panel__body {
      //padding: 8px;
    }
  }

  &--cropper {
    padding: 0;
    overflow: hidden;
  }

  &--header {
    margin-bottom: 20px;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  .user-posts.panel {
    padding: 20px 0;
  }
}

.panel__body {
  width: 100%;
}

.panel__footer {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  margin-top: 12px;
  padding-top: 16px;
  border-top: 1px solid $colorGrey5;
  gap: 8px;
  flex-wrap: wrap;
  //.btn {
  //  margin-top: 16px;
  //}

  //.btn--link {
    //padding: 4px 8px;
    //font-weight: 700;
    //font-size: 16px;
    //line-height: 20px;
    //
    //&:hover{
    //  //color: $colorPrimary;
    //  //background: #FF06500D;
    //}
    //
    //&:disabled {
    //  opacity: 0.7;
    //}
  //}
}

.panel__footer-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  //justify-content: space-around;
}

.panel__footer-links {
  @include reset-list();
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0 16px;
  width: 100%;

  li {
    //margin-top: 20px;

  }

  a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8799B2;
    white-space: nowrap;
  }
}

.panel__section {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $colorGrey5;
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

.panel__icon {
  display: block;
  margin-bottom: 4px;
}

.panel__btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $colorGrey1;
}
